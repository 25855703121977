const generateSuggestedDatumList = (allDatum, fileHeaderData, headerName) => {
  const datumHeader = headerName ? headerName.split('.').pop() : headerName;
  let suggestedDatumIds = [];
  if (datumHeader && fileHeaderData[datumHeader]) {
    suggestedDatumIds = fileHeaderData[datumHeader].map(
      (h) => h.datumDefinitionId
    );
  }
  return allDatum
    .map(({ id, name, description }) => ({
      index: suggestedDatumIds.indexOf(id),
      label: name,
      pill: suggestedDatumIds.includes(id) ? 'Recommended' : null,
      subtext: description,
      value: id,
    }))
    .sort((a, b) => (a.index === -1 && b.index === -1 ? 0 : a.index - b.index))
    .sort(
      (a, b) =>
        (a.pill === null) - (b.pill === null) ||
        +(a.pill > b.pill) ||
        -(a.pill < b.pill)
    );
};

export default generateSuggestedDatumList;
