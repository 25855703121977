import React from 'react';
import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import Button from '@shoreag/base/Button';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const makeOnDragEndFunction = (fields) => (result) => {
  if (!result.destination) {
    return;
  }
  fields.move(result.source.index, result.destination.index);
};

const inputListSx = {
  fontSize: '12px',
  fontWeight: '700',
};
const InputListWrapper = ({
  children,
  enableBuildSchema,
  title,
  name,
  push,
}) => {
  return (
    <>
      {name === 'datumIds' && (
        <Box sx={{ borderRadius: 1, boxShadow: 1 }}>
          <Box
            sx={{
              '> *': {
                flex: 1,
                ml: '0.5rem',
                mr: '0.25rem',
                my: 0,
              },
              '@media (max-width: 500px)': {
                display: 'none',
              },
              bg: 'white',
              display: 'flex',
              fontSize: '1',
              pb: '0',
              pt: '1rem',
              px: '1.75rem',
            }}
          >
            <Box sx={{ maxWidth: [null, null, '55px'] }} />
            <Box
              sx={{
                maxWidth: [null, null, enableBuildSchema ? '350px' : '250px'],
                ...inputListSx,
              }}
            >
              Field Name *
            </Box>
            {!enableBuildSchema && (
              <Box sx={{ maxWidth: [null, null, '150px'], ...inputListSx }}>
                Type *
              </Box>
            )}
            <Box sx={{ ...inputListSx }}>
              {enableBuildSchema ? 'Datum' : 'Datum / Schema *'}
            </Box>
            <Box sx={{ maxWidth: [null, null, '145px'], ...inputListSx }}>
              Primary *
            </Box>
            <Box sx={{ maxWidth: [null, null, '150px'], ...inputListSx }}>
              Mandatory *
            </Box>
            <Box sx={{ maxWidth: [null, null, '150px'], ...inputListSx }}>
              Multiple *
            </Box>
          </Box>
        </Box>
      )}
      <FieldArray
        name={name}
        subscription={{
          move: true,
          remove: true,
        }}
      >
        {({ fields }) => (
          <DragDropContext onDragEnd={makeOnDragEndFunction(fields)}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <>
                  <Box
                    ref={provided.innerRef}
                    sx={{
                      '> *': {
                        boxShadow: (p) =>
                          `${snapshot.isDraggingOver && p.shadows[0]}`,
                      },
                      backgroundColor: snapshot.isDraggingOver
                        ? 'grays.0'
                        : 'white',
                      boxShadow: (p) =>
                        `inset ${snapshot.isDraggingOver && p.shadows[1]}`,
                      px: 5,
                      py: 3,
                      width: 'auto',
                    }}
                  >
                    {fields.map((name, index) =>
                      React.Children.map(children, (child) =>
                        React.cloneElement(child, {
                          fields,
                          index,
                          name,
                        })
                      )
                    )}
                  </Box>
                  {provided.placeholder}
                </>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </FieldArray>
      {!enableBuildSchema && (
        <Box bg="white" display="flex" justifyContent="right" pb="5" px="6">
          <Button onClick={push} simple>
            {title}
          </Button>
        </Box>
      )}
    </>
  );
};

InputListWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  enableBuildSchema: PropTypes.bool,
  name: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

InputListWrapper.defaultProps = {
  enableBuildSchema: false,
};

export default InputListWrapper;
