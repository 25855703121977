import Button from '@shoreag/base/Button';
import Card from '@shoreag/base/Card';
import Dropdown from '@shoreag/base/Dropdown';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Spinner from '@shoreag/base/Spinner';
import Table from '@shoreag/base/Table';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { AuthContext } from '@shoreag/auth';
import { Field, Form } from 'react-final-form';
import { Query } from 'react-apollo';
import KeyValuePairs from '../KeyValuePairs';
import ResponsiveEllipsis from '../ResponsiveEllipsis';
import Route from '../Route';
import TableWrapper from '../TableWrapper';
import ToolbarButton from '../ToolbarButton';
import enhanceKeyValuePairEntries from '../../utilities/enhance-key-value-pairs';
import filterKeyValuePairEntries from '../../utilities/filter-key-value-pair-entries';
import isPermitted from '../../utilities/is-permitted';
import parseUuid from '../../utilities/parse-uuid';
import schemaQuery from '../../graphql/queries/schema.gql';
import {
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '../../utilities/constants';
import getDatumSpecification from '../../utilities/get-datum-specification';

const SchemaDetailsPage = ({ navigate, schemaIdWithVersion }) => {
  const { user } = useContext(AuthContext);
  const { id } = parseUuid(schemaIdWithVersion);

  return (
    <>
      <Query
        fetchPolicy="network-only"
        query={schemaQuery}
        variables={{ id: schemaIdWithVersion }}
      >
        {({ data, loading }) => {
          if (loading) return <Spinner />;
          const schema = get(data, 'datasetSchema');
          const latestVersionNumber = get(
            data,
            'datasetSchema.latestVersionNumber',
            1
          );
          const schemaVersion = get(data, 'datasetSchema.versions[0]', {});
          const versionDropdownList = Array.from(
            { length: latestVersionNumber },
            (_, index) => latestVersionNumber - index
          ).map((versionNumber) => ({
            label: `Version ${versionNumber}`,
            value: versionNumber,
          }));
          const metadata = {
            ...schema,
            createdAt: schemaVersion.createdAt || schema.createdAt,
            partner: schemaVersion.partner || {},
            tags: schemaVersion.tags || [],
          };
          delete metadata.latestVersionNumber;

          return (
            <Route
              header={{
                icon: 'lists',
                leftContainer: (
                  <Form
                    initialValues={{
                      version: parseInt(schemaVersion.versionNumber, 10),
                    }}
                    onSubmit={noop}
                    render={() => (
                      <Field
                        component={Dropdown}
                        name="version"
                        onChange={(value) =>
                          navigate(`/schemas/${id}:${value}`, {
                            replace: true,
                          })
                        }
                        options={versionDropdownList}
                        wrapperSx={{
                          maxWidth: ['100%', null, null, '150px'],
                          mb: [5, null, null, 0],
                          minWidth: '150px',
                          mx: [null, null, null, 5],
                        }}
                      />
                    )}
                  />
                ),
                rightContainer: isPermitted({
                  desiredAction: PERMISSION_ACTIONS.W,
                  desiredResource: PERMISSION_RESOURCES.SCHEMAS,
                  ...user,
                }) && (
                  <ToolbarButton
                    icon="edit"
                    label="Edit Schema"
                    link={`/schemas/${schemaIdWithVersion}/edit`}
                  />
                ),
                title: schema.name,
                type: 'Schema',
              }}
              isPrivate
            >
              <Card>
                <h2>Metadata</h2>
                <KeyValuePairs
                  pairs={Object.entries(metadata)
                    .filter(filterKeyValuePairEntries)
                    .map(enhanceKeyValuePairEntries)}
                />
              </Card>
              <Card>
                <h2>Datum Definitions</h2>
                <TableWrapper>
                  <Table
                    header={[
                      'Name',
                      'Field Name',
                      'Description',
                      'Type',
                      'Spec',
                      'Examples',
                      'Active',
                      'Primary',
                      'Mandatory',
                      'Multiple',
                    ]}
                    rows={get(schemaVersion, 'definitions', []).map((v) => [
                      <Button
                        link={
                          v.nestedSchemaWithVersion
                            ? `/schemas/${v.nestedSchemaWithVersion.id}`
                            : `/datums/${v.id.split(':')[0]}`
                        }
                        simple
                        variant="buttons.link"
                      >
                        <ResponsiveEllipsis
                          text={
                            v.nestedSchemaWithVersion
                              ? v.nestedSchemaWithVersion.name
                              : v.name
                          }
                        />
                      </Button>,
                      v.fieldName,
                      <ResponsiveEllipsis text={v.description} />,
                      <ResponsiveEllipsis text={v.definitionType} />,
                      getDatumSpecification(v),
                      <ResponsiveEllipsis text={v.examples} />,
                      v.isDeleted ? 'Inactive' : 'Active',
                      v.primary ? 'Yes' : 'No',
                      v.isMandatory ? 'Yes' : 'No',
                      v.multiple ? 'Yes' : 'No',
                    ])}
                  />
                </TableWrapper>
              </Card>
            </Route>
          );
        }}
      </Query>
    </>
  );
};

SchemaDetailsPage.propTypes = {
  navigate: PropTypes.func.isRequired,
  schemaIdWithVersion: PropTypes.string,
};

SchemaDetailsPage.defaultProps = {
  schemaIdWithVersion: null,
};

export default SchemaDetailsPage;
