const filterKeyValuePairEntries = ([key, value]) =>
  ![
    'clientId',
    'comments',
    'computationId',
    'edges',
    'id',
    'passed',
    'pipelineId',
    'recordId',
    'status',
    'statusCode',
    'stepType',
    'timeTaken',
    'versions',
  ].includes(key) && !(Array.isArray(value) && !value.length);

export default filterKeyValuePairEntries;
