// import DatePicker from '../../DateRangePicker';
import DatePicker from '@shoreag/base/DatePicker';
import Input from '@shoreag/base/Input';
import React, { useContext } from 'react';
import { Field } from 'react-final-form';
import { get, uniqBy } from 'lodash';
import { useQuery } from 'react-apollo';
import { AuthContext } from '@shoreag/auth';
import MultiSelectDropdown from '../../MultiSelectDropdown';
import tradingPartners from '../../../utilities/get-trading-partners';

const DashboardFilterFormSection = ({ filter, filterKey }) => {
  let result;
  const { label, options, query, type } = filter;
  const placeholder = 'Search...';

  switch (type) {
    case 'Input': {
      result = (
        <Field
          component={Input}
          label={label}
          labelSx={{
            fontSize: 1,
          }}
          name={filterKey}
          placeholder={placeholder}
        />
      );
      break;
    }
    case 'DatePicker': {
      result = (
        <Field
          component={DatePicker}
          dateDisplayFormat="M/d/yyyy"
          label={label}
          labelSx={{
            fontSize: 1,
          }}
          name={filterKey}
        />
      );
      break;
    }
    case 'Dropdown': {
      result = (
        <Field
          component={MultiSelectDropdown}
          label={label}
          labelSx={{
            fontSize: 1,
          }}
          name={filterKey}
          options={options}
          placeholder={placeholder}
        />
      );
      break;
    }
    case 'DropdownQuery': {
      const { generateOptions, graphQLQuery, name, variables } = query;
      const { data, loading } = variables
        ? useQuery(graphQLQuery, { fetchPolicy: 'network-only', variables })
        : useQuery(graphQLQuery, { fetchPolicy: 'network-only' });

      if (name === 'allPartner') {
        const { user } = useContext(AuthContext);
        result = (
          <Field
            component={MultiSelectDropdown}
            isLoading={loading}
            label={label}
            labelSx={{
              fontSize: 1,
            }}
            name={filterKey}
            options={uniqBy(
              tradingPartners(get(data, name, []), user).map((row) =>
                generateOptions(row)
              ),
              'value'
            )}
            placeholder={placeholder}
          />
        );
      } else {
        result = (
          <Field
            component={MultiSelectDropdown}
            isLoading={loading}
            label={label}
            labelSx={{
              fontSize: 1,
            }}
            name={filterKey}
            options={uniqBy(
              get(data, name, []).map((row) => generateOptions(row)),
              'value'
            )}
            placeholder={placeholder}
          />
        );
      }
      break;
    }
    default: {
      result = null;
    }
  }
  return result;
};

export default DashboardFilterFormSection;
