import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { capitalCase } from 'change-case';

const KeyValuePairs = ({ autoTitleCase, pairs, ...rest }) => {
  if (!pairs.length) return null;

  const visiblePairs = pairs.filter(
    (p) => p[2] === true || typeof p[2] === 'undefined'
  );

  const firstColumnLength = Math.ceil(visiblePairs.length / 2);

  const mapPair = ([key, value], i) => {
    let formattedValue = value;
    const isObject = typeof value === 'object' && value !== null;
    const isArray = Array.isArray(value);

    if (
      React.isValidElement(value) ||
      (isObject && value.prototype && value.prototype.isReactComponent)
    ) {
      formattedValue = value;
    } else if (isArray && !value.some((v) => typeof v !== 'string')) {
      formattedValue = value.reduce(
        (acc, v, i) => `${acc}${i !== 0 ? ', ' : ''}${v}`,
        ''
      );
    } else if (isArray || isObject) {
      formattedValue = <pre>{JSON.stringify(value, null, 2)}</pre>;
    } else if (value) {
      formattedValue = String(value);
    } else {
      formattedValue = '-';
    }

    return (
      <Box key={i}>
        <Box
          as="h3"
          sx={{
            color: 'textSubtle',
            fontSize: 2,
            mt: 5,
            textTransform: 'uppercase',
          }}
        >
          {autoTitleCase ? capitalCase(key) : key}
        </Box>
        <Box sx={{ mt: 1, wordWrap: 'break-word' }}>{formattedValue}</Box>
      </Box>
    );
  };

  return (
    <Box display={{ lg: 'flex' }} {...rest}>
      <Box width={{ lg: 1 / 2 }}>
        {visiblePairs.slice(0, firstColumnLength).map(mapPair)}
      </Box>
      <Box width={{ lg: 1 / 2 }}>
        {visiblePairs.slice(firstColumnLength).map(mapPair)}
      </Box>
    </Box>
  );
};

KeyValuePairs.propTypes = {
  autoTitleCase: PropTypes.bool,
  pairs: PropTypes.arrayOf(PropTypes.array).isRequired,
};

KeyValuePairs.defaultProps = {
  autoTitleCase: true,
};

export default KeyValuePairs;
