import React from 'react';
import PropTypes from 'prop-types';
import InputListWrapper from '../InputListWrapper';
import TagsFormSection from './TagsFormSection';

const TagsForm = ({ push }) => (
  <InputListWrapper
    name="tags"
    push={() => push('tags', undefined)}
    title="Add Tags"
  >
    <TagsFormSection />
  </InputListWrapper>
);

TagsForm.propTypes = {
  push: PropTypes.func.isRequired,
};

export default TagsForm;
