import AutoSave from '@shoreag/base/AutoSave';
import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import React from 'react';
import noop from 'lodash/noop';
import { Form } from 'react-final-form';
import { Location } from '@reach/router';
import { navigate } from 'gatsby';
import { ParentSize } from '@vx/responsive';
import DashboardFilterFormSection from './DashboardFilterFormSection';
import setDashboardURL from '../../utilities/set-dashboard-url';

const DashboardFilterForm = ({ filters, page, showFilters, sort }) => {
  const { filterOrder, values } = filters;
  return (
    <Box
      sx={{
        height: 'auto',
        maxHeight: showFilters ? '300px' : 0,
        overflow: showFilters ? 'visible' : 'hidden',
        transition: 'max-height 0.5s ease-in-out',
      }}
    >
      <ParentSize>
        {({ width }) => {
          const fieldWidth =
            filterOrder.length >= 4
              ? width / 4 - 10
              : width / filterOrder.length - 15;
          return (
            <Location>
              {({ location: { pathname } }) => (
                <Form
                  initialValues={values}
                  onSubmit={noop}
                  render={() => (
                    <Box
                      sx={{
                        alignItems: 'flex-end',
                        bg: 'grays.2',
                        borderRadius: 2,
                        display: 'flex',
                        flexWrap: 'wrap',
                        fontSize: 2,
                        justifyContent: 'flex-start',
                        mb: 5,
                        p: 5,
                      }}
                    >
                      <AutoSave
                        save={(all, changed, added, deleted, values) => {
                          return navigate(
                            setDashboardURL({
                              filters,
                              filterValues: values,
                              page: {
                                offset: 0,
                                pageSize: page.pageSize,
                              },
                              pathname,
                              sort,
                            }),
                            { replace: true }
                          );
                        }}
                      />
                      {filterOrder.map((key) => {
                        return filters[key] ? (
                          <Box
                            key={key}
                            sx={{
                              p: 4,
                              width: fieldWidth,
                            }}
                          >
                            <DashboardFilterFormSection
                              filter={filters[key]}
                              filterKey={key}
                            />
                          </Box>
                        ) : null;
                      })}
                    </Box>
                  )}
                />
              )}
            </Location>
          );
        }}
      </ParentSize>
    </Box>
  );
};

DashboardFilterForm.propTypes = {
  filters: PropTypes.shape({
    filterOrder: PropTypes.arrayOf(PropTypes.string),
    values: PropTypes.shape({}),
  }).isRequired,
  page: PropTypes.shape({
    offset: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  showFilters: PropTypes.bool.isRequired,
  sort: PropTypes.shape({}).isRequired,
};

export default DashboardFilterForm;
