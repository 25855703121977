const findDuplicateStringsInArray = (inputArray) => {
  const duplicateValues = {};

  inputArray.forEach((item) => {
    if (!duplicateValues[item]) {
      duplicateValues[item] = 0;
    }
    duplicateValues[item] += 1;
  });

  const duplicateItems = Object.entries(duplicateValues)
    .filter(([, value]) => value >= 2)
    .map((v) => v[0]);

  return duplicateItems;
};

export default findDuplicateStringsInArray;
