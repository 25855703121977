import React from 'react';
import { Router } from '@reach/router';
import NotFoundPage from './404';
import SchemasDashboardPage from '../../components/SchemaDashboardPage';
import SchemasCreatePage from '../../components/SchemaCreatePage';
import SchemaDetailsPage from '../../components/SchemaDetailsPage';

const SchemasRouter = (props) => (
  <Router>
    <SchemaDetailsPage {...props} path="/schemas/:schemaIdWithVersion" />
    <SchemasCreatePage {...props} path="/schemas/:schemaIdWithVersion/edit" />
    <SchemasCreatePage {...props} path="/schemas/create" />
    <SchemasDashboardPage {...props} path="/schemas/" />
    <NotFoundPage default />
  </Router>
);

export default SchemasRouter;
