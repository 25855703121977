import Box from '@shoreag/base/Box';
import Input from '@shoreag/base/Input';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { required } from '@shoreag/validations';
import Icon from '../Icon';

const TagsFormSection = ({ name, index, fields }) => (
  <Box key={name} alignItems="flex-start" display="flex" mt="sm">
    <Field
      component={Input}
      name={`${name}.name`}
      placeholder="Name"
      validate={required}
      wrapperSx={{ mt: 0 }}
    />
    <Field
      component={Input}
      name={`${name}.value`}
      placeholder="Value"
      validate={required}
      wrapperSx={{ mt: 0, mx: 4 }}
    />
    <Icon
      cursor="pointer"
      height="15px"
      mt="10px"
      onClick={() => fields.remove(index)}
      svg="close"
      width="15px"
    />
  </Box>
);

TagsFormSection.propTypes = {
  fields: PropTypes.shape({
    remove: PropTypes.func,
  }),
  index: PropTypes.number,
  name: PropTypes.string,
};

TagsFormSection.defaultProps = {
  fields: {},
  index: 0,
  name: '',
};

export default TagsFormSection;
