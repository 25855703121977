const getSampleValues = (fileHeaderData, headerName) => {
  if (
    headerName &&
    fileHeaderData.sampleData &&
    fileHeaderData.sampleData[headerName]
  ) {
    const sampleData = fileHeaderData.sampleData[headerName];
    const uniqueSamples = [...new Set(sampleData)].filter((d) => d !== '');
    return uniqueSamples.join(', ');
  }
  return null;
};

export default getSampleValues;
