const getDatumSpecification = ({
  definitionType,
  format,
  max,
  min,
  pattern,
}) => {
  switch (definitionType) {
    case 'integer': {
      if (min !== null || max !== null) {
        return `${min || 0} - ${max || 0}`;
      }

      break;
    }

    case 'number': {
      if (min !== null || max !== null) {
        return `${min || 0} - ${max || 0}`;
      }

      break;
    }

    case 'string': {
      if (format) {
        return format === 'regex' ? pattern || '-' : format;
      }

      break;
    }

    default: {
      // noop
    }
  }

  return '-';
};

export default getDatumSpecification;
