import PropTypes from 'prop-types';
import React from 'react';
import ColumnDefinitionsFormSection from './ColumnDefinitionsFormSection';
import InputListWrapper from '../InputListWrapper';

const ColumnDefinitionsForm = ({
  enableBuildSchema,
  fileHeaderData,
  push,
  ...rest
}) => {
  return (
    <InputListWrapper
      enableBuildSchema={enableBuildSchema}
      name="datumIds"
      push={() => {
        push('datumIds', { isNestedSchema: 'datum' });
      }}
      title={rest.title}
    >
      <ColumnDefinitionsFormSection
        enableBuildSchema={enableBuildSchema}
        fileHeaderData={fileHeaderData}
      />
    </InputListWrapper>
  );
};

ColumnDefinitionsForm.propTypes = {
  enableBuildSchema: PropTypes.bool,
  fileHeaderData: PropTypes.shape({}),
  push: PropTypes.func.isRequired,
};

ColumnDefinitionsForm.defaultProps = {
  enableBuildSchema: false,
  fileHeaderData: {},
};

export default ColumnDefinitionsForm;
