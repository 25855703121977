import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import React from 'react';

const FieldCondition = ({ children, is, when }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

FieldCondition.propTypes = {
  children: PropTypes.node.isRequired,
  is: PropTypes.string.isRequired,
  when: PropTypes.string.isRequired,
};

export default FieldCondition;
