import Dropdown from '@shoreag/base/Dropdown';
import Box from '@shoreag/base/Box';
import Input from '@shoreag/base/Input';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';
import { Draggable } from 'react-beautiful-dnd';
import { Field } from 'react-final-form';
import { flatten } from 'lodash';
import { required } from '@shoreag/validations';
import Checkbox from '@shoreag/base/Checkbox';
import { TooltipTrigger } from '@shoreag/base/Tooltip';
import Icon from '../../Icon';
import allDatumOptimizedQuery from '../../../graphql/queries/all-datum-optimized.gql';
import allSchemaOptimizedQuery from '../../../graphql/queries/all-schema-optimized.gql';
import generateSuggestedDatumList from '../utilities/generate-suggested-datum-list';
import getSampleValues from '../utilities/generate-sample-data';
import FieldCondition from '../FieldCondition';

const wrapperSx = {
  '& > div': {
    ml: 3,
    mt: 4,
  },
  alignItems: 'center',
  display: 'flex',
  input: {
    height: '18px',
    width: '18px',
  },
  maxWidth: '135px',
};
const ColumnDefinitionsFormSection = ({
  enableBuildSchema,
  index,
  name,
  fields,
  fileHeaderData,
}) => {
  const [externalAsyncOptions, setExternalAsyncOptions] = useState([]);
  const filedHeaders = get(fileHeaderData, 'headers', []);
  const exampleData = getSampleValues(fileHeaderData, filedHeaders[index]);
  return (
    <Draggable
      key={name}
      draggableId={name}
      index={index}
      isDragDisabled={!!enableBuildSchema}
    >
      {(provided, snapshot) => (
        <ApolloConsumer>
          {(client) => (
            <Box
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={{
                '& > div': {
                  '&:last-of-type': {
                    flex: 'none',
                    mt: '0.6rem',
                    width: 'auto',
                  },
                  flex: '1',
                  // mb: 3,
                  mt: 0,
                  mx: 1,
                  textOverflow: 'ellipsis',
                  width: '100%',
                },
                '&:before': {
                  color: snapshot.isDragging ? 'grays.5' : 'grays.3',
                  content: enableBuildSchema ? '""' : '"\\22EE"',
                  fontSize: 6,
                  mb: 3,
                  ml: 0,
                  mr: 2,
                  mt: 0,
                },
                '&:hover': {
                  backgroundColor: 'grays.0',
                },
                '@media (max-width: 500px)': {
                  flexDirection: 'column',
                },
                alignItems: 'flex-start',
                backgroundColor: snapshot.isDragging ? 'header' : 'white',
                boxShadow: (p) => `${snapshot.isDragging && p.shadows[0]}`,
                display: 'flex',
                pb: 0,
                pl: 1,
                pr: 3,
                pt: 3,
                transition: 'background-color 0.35s ease-in-out',
                userSelect: 'none',
              }}
            >
              {enableBuildSchema && (
                <TooltipTrigger as="span" data-tip={exampleData} id="tooltip">
                  <Icon
                    color={exampleData ? 'textSubtle' : 'grays.1'}
                    disable={!exampleData}
                    height="18px"
                    svg="info"
                    sx={{
                      cursor: exampleData ? 'pointer' : 'auto',
                      mt: 4,
                    }}
                    width="18px"
                  />
                </TooltipTrigger>
              )}
              <Field
                component={Checkbox}
                disabled={!index}
                errorSx={{
                  ml: 4,
                  mt: 0,
                }}
                name={`${name}.isChecked`}
                type="checkbox"
                wrapperSx={{
                  ...wrapperSx,
                  maxWidth: '30px',
                }}
              />
              <Field
                component={Input}
                name={`${name}.fieldName`}
                placeholder="FieldName"
                title={filedHeaders[index] || ''}
                validate={required}
                wrapperSx={{
                  maxWidth: [null, null, enableBuildSchema ? '350px' : '250px'],
                }}
              />

              {!enableBuildSchema && (
                <Field
                  component={Dropdown}
                  name={`${name}.isNestedSchema`}
                  options={[
                    { label: 'Datum', value: 'datum' },
                    { label: 'Schema', value: 'schema' },
                  ]}
                  placeholder="Select Type"
                  validate={required}
                  wrapperSx={{ maxWidth: [null, null, '140px'] }}
                />
              )}
              <FieldCondition is="schema" when={`${name}.isNestedSchema`}>
                <Field
                  cacheOptions={false}
                  component={Dropdown}
                  externalAsyncOptions={externalAsyncOptions}
                  loadOptions={async (query) => {
                    const {
                      data: { allSchema },
                    } = await client.query({
                      fetchPolicy: 'network-only',
                      query: allSchemaOptimizedQuery,
                      variables: { name: query, optimized: true },
                    });
                    return flatten(
                      allSchema.map((d) =>
                        d.versions.map((version) => ({
                          label: `${d.name} - v${version.versionNumber}`,
                          subtext: d.description,
                          value: `${d.id}:${version.versionNumber}`,
                        }))
                      )
                    );
                  }}
                  name={`${name}.datumId`}
                  placeholder="Search by schema name"
                  setExternalAsyncOptions={setExternalAsyncOptions}
                  validate={required}
                />
              </FieldCondition>
              <FieldCondition is="datum" when={`${name}.isNestedSchema`}>
                <Field
                  cacheOptions={false}
                  component={Dropdown}
                  externalAsyncOptions={externalAsyncOptions}
                  loadOptions={async (query) => {
                    const {
                      data: { allDatum },
                    } = await client.query({
                      fetchPolicy: 'network-only',
                      query: allDatumOptimizedQuery,
                      variables: { name: query, optimized: true },
                    });
                    if (Object.keys(fileHeaderData).length) {
                      const headerName = filedHeaders[index];
                      return generateSuggestedDatumList(
                        allDatum,
                        fileHeaderData,
                        headerName
                      );
                    }
                    return allDatum.map(({ id, name, description }) => ({
                      label: name,
                      subtext: description,
                      value: id,
                    }));
                  }}
                  name={`${name}.datumId`}
                  placeholder="Search by datum name"
                  setExternalAsyncOptions={setExternalAsyncOptions}
                  validate={required}
                />
              </FieldCondition>
              <Field
                component={Checkbox}
                errorSx={{
                  ml: 4,
                  mt: 0,
                }}
                name={`${name}.primary`}
                type="checkbox"
                wrapperSx={wrapperSx}
              />
              <Field
                component={Checkbox}
                errorSx={{
                  ml: 4,
                  mt: 0,
                }}
                name={`${name}.isMandatory`}
                type="checkbox"
                wrapperSx={wrapperSx}
              />
              <Field
                component={Checkbox}
                errorSx={{
                  ml: 4,
                  mt: 0,
                }}
                name={`${name}.multiple`}
                type="checkbox"
                wrapperSx={wrapperSx}
              />
              <Icon
                cursor="pointer"
                disabled={!index}
                height="15px!important"
                onClick={() => fields.remove(index)}
                svg="close"
                sx={{
                  cursor: 'pointer',
                  opacity: !index ? 0 : 1,
                  pointerEvents: !index ? 'none' : 'auto',
                }}
                width="15px!important"
              />
            </Box>
          )}
        </ApolloConsumer>
      )}
    </Draggable>
  );
};

ColumnDefinitionsFormSection.defaultProps = {
  enableBuildSchema: false,
  fields: {},
  fileHeaderData: {},
  index: 0,
  name: '',
};

ColumnDefinitionsFormSection.propTypes = {
  enableBuildSchema: PropTypes.bool,
  fields: PropTypes.shape({
    remove: PropTypes.func,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        fieldName: PropTypes.string,
      })
    ),
  }),
  fileHeaderData: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.string),
  }),
  index: PropTypes.number,
  name: PropTypes.string,
};

export default ColumnDefinitionsFormSection;
