import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Table from '@shoreag/base/Table';
import copy from 'clipboard-copy';
import formatDateTimeConcise from '@shoreag/helpers/format-date-time-concise';
import get from 'lodash/get';
import { AuthContext } from '@shoreag/auth';
import { Query } from 'react-apollo';
import ButtonGroups from '../ButtonGroups';
import DashboardFilterForm from '../DashboardFilterForm';
import DashboardPaginator from '../DashboardPaginator';
import DotsIcon from '../DotsIcon';
import LinkWithVersion from '../LinkWithVersion';
import ResponsiveEllipsis from '../ResponsiveEllipsis';
import Route from '../Route';
import TagTooltip from '../TagTooltip';
import TagTooltipBox from '../TagTooltip/TagTooltipBox';
import Tags from '../Tags';
import ToolbarButton from '../ToolbarButton';
import allSchemaQuery from '../../graphql/queries/all-schema.gql';
import dashboardConstants from '../../utilities/dashboard-constants';
import isPermitted from '../../utilities/is-permitted';
import setActionDropdown from '../../utilities/set-action-dropdown';
import setDashboardURL from '../../utilities/set-dashboard-url';
import {
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '../../utilities/constants';

const SchemaDashboardPage = ({ location, navigate }) => {
  const { user } = useContext(AuthContext);
  const [showFilters, setShowFilters] = useState(false);

  const {
    filters,
    sortCriteria,
    pageCriteria,
  } = dashboardConstants.getCriterias({
    search: location.search,
    type: 'schema',
  });

  const query = dashboardConstants.getQuery({
    filters,
    page: pageCriteria,
    sort: sortCriteria,
    user,
  });

  return (
    <Route
      header={{
        icon: 'lists',
        rightContainer: (
          <ButtonGroups>
            <ToolbarButton
              active={showFilters || filters.filterApplied}
              icon={filters.filterApplied ? 'filter' : 'menu'}
              label="Filters"
              onClick={() => setShowFilters(!showFilters)}
            />
            {isPermitted({
              desiredAction: PERMISSION_ACTIONS.W,
              desiredResource: PERMISSION_RESOURCES.SCHEMAS,
              ...user,
            }) && (
              <ToolbarButton
                icon="plus"
                label="Create Schema"
                link="/schemas/create"
              />
            )}
          </ButtonGroups>
        ),
        title: 'Dashboard',
        type: 'Schema',
      }}
      isPrivate
    >
      <DashboardFilterForm
        filters={filters}
        page={pageCriteria}
        showFilters={showFilters}
        sort={sortCriteria}
      />

      <Query
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
        query={allSchemaQuery}
        variables={{ searchBody: JSON.stringify(query) }}
      >
        {({ data, loading }) => {
          const schemas = get(data, 'allSchema', []);

          return (
            <>
              <Card m={0} p={5}>
                <Table
                  header={[
                    { label: 'Name', value: 'schema.name' },
                    { label: 'Description', value: 'schema.description' },
                    'Partner Name',
                    'Tags',
                    { label: 'Creation Date', value: 'schema.createdAt' },
                    'Datums',
                    '',
                  ]}
                  isLoading={loading}
                  onSortUpdate={(sortBy, orderBy) => {
                    return navigate(
                      setDashboardURL({
                        filters,
                        filterValues: filters.values,
                        page: pageCriteria,
                        pathname: location.pathname,
                        sort: {
                          sortBy,
                          sortDirection: orderBy,
                        },
                      }),
                      { replace: true }
                    );
                  }}
                  orderBy={sortCriteria.sortDirection}
                  rows={schemas.map((schema) => {
                    const {
                      dropdownItems,
                      dropdownActions,
                    } = setActionDropdown([
                      {
                        action: `/schemas/${schema.id}:${get(
                          schema,
                          'versions[0].versionNumber',
                          '1.0'
                        )}`,
                        display: true,
                        label: ['view', 'View'],
                      },
                      {
                        action: () => copy(schema.id),
                        display: true,
                        label: ['copy', 'Copy ID'],
                      },
                    ]);

                    const schemaLatestVersion = parseInt(
                      get(schema, 'versions[0].versionNumber', '1.0'),
                      10
                    );

                    return [
                      <LinkWithVersion
                        link={`/schemas/${schema.id}:${schemaLatestVersion}`}
                        name={schema.name}
                        version={schemaLatestVersion}
                      />,
                      <ResponsiveEllipsis text={schema.description} />,
                      schema.versions[0] && schema.versions[0].partner
                        ? schema.versions[0].partner.partnerName
                        : '-',
                      <Tags tags={schema.versions[0].tags} />,
                      formatDateTimeConcise(schema.createdAt),
                      schema.versions[0] ? (
                        <TagTooltip
                          id="tags"
                          tags={schema.versions[0].definitions.map(
                            (definition) =>
                              definition.fieldName || definition.name
                          )}
                        >
                          {schema.versions[0].definitions.length.toString()}
                        </TagTooltip>
                      ) : (
                        0
                      ),
                      <Box sx={{ float: 'right' }}>
                        <DropdownMenu
                          button={<DotsIcon />}
                          itemActions={dropdownActions}
                          items={dropdownItems}
                        />
                      </Box>,
                    ];
                  })}
                  sortBy={sortCriteria.sortBy}
                />
                <TagTooltipBox
                  tooltipProps={{
                    sx: {
                      display: 'flex!important',
                      padding: '0 !important',
                      pointerEvents: 'auto!important',
                    },
                  }}
                />
              </Card>
              <DashboardPaginator
                filters={{
                  page: pageCriteria.offset,
                }}
                goToNextPage={() =>
                  navigate(
                    setDashboardURL({
                      filters,
                      filterValues: filters.values,
                      page: {
                        offset: pageCriteria.offset + pageCriteria.pageSize,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  )
                }
                goToPreviousPage={() =>
                  navigate(
                    setDashboardURL({
                      filters,
                      filterValues: filters.values,
                      page: {
                        offset: pageCriteria.offset - pageCriteria.pageSize,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  )
                }
                isClientSidePagination
                pageTotal={schemas.length}
              />
            </>
          );
        }}
      </Query>
    </Route>
  );
};

SchemaDashboardPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default SchemaDashboardPage;
